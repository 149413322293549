<template>
    <v-flex fill-height>
        <v-layout justify-space-between pa-2 row>
            <v-flex v-t="'sa.support.actions.offers-template-duplicate.title'" headline shrink />
        </v-layout>
		<v-layout fill-height pa-4>
            <v-flex>
                <v-layout column justify-center>
                    <v-flex my-3 shrink>
                        <v-layout column>
                            <v-flex v-t="'sa.support.actions.offers-template-duplicate.from-accounting-firm'" class="title" mb-3 />
                                <v-autocomplete
                                    v-model="selectedAccountingFirmFromId"
                                    color="primary"
                                    :items="accountingFirms"
                                    :label="$t('accounting_firm')"
                                    :loading="loadingAccountingFirmsList"
                                />
                                <v-flex v-t="'sa.support.actions.offers-template-duplicate.to-accounting-firm'" class="title" mb-3 />
                                <v-autocomplete
                                    v-model="selectedAccountingFirmTargetId"
                                    color="primary"
                                    :items="accountingFirms"
                                    :label="$t('accounting_firm')"
                                    :loading="loadingAccountingFirmsList"
                                />
                            </v-layout>
                    </v-flex>
                    <v-checkbox v-model="eraseCatalog" color="primary" :label="$t('sa.support.actions.offers-template-duplicate.erase-catalog')" hide-details />
                    <v-checkbox v-model="eraseTemplates" color="primary" :label="$t('sa.support.actions.offers-template-duplicate.erase-templates')" hide-details />
                    <v-flex my-3 shrink>
                        <WButton :disabled="!selectedAccountingFirmTargetId || !selectedAccountingFirmFromId" @click="execute">{{ $t('actions.launch') }}</WButton>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-flex>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'
export default {
    name: 'CatalogServicesAndTemplateDuplication',
    mixins: [SuperAdminModuleGuard],
    data: function () {
		return {
			accountingFirms: new Array(),
            selectedAccountingFirmTargetId: null,
            selectedAccountingFirmFromId: null,
            eraseCatalog: false,
            eraseTemplates: false,
            loadingAccountingFirmsList: false,
        }
    },
    created: function () {
		this.getAccountingFirms()
	},
    methods: {
        getAccountingFirms: function () {
			this.loadingAccountingFirmsList = true
			this.service
				.getAccountingFirms()
				.then(accountingFirms => {
					return accountingFirms.map(accountingFirm => {
						return {
							text: accountingFirm.name,
							value: accountingFirm.id
						}
					})
				})
				.then(accountingFirms => {
					this.accountingFirms = accountingFirms
                    this.selectedAccountingFirmFromId = accountingFirms.find(af => af.text == 'Catalogue de prestations test')?.value
				})
				.finally(() => {
					this.loadingAccountingFirmsList = false
				})
		},
        execute: function () {
            return this.service.executeCommand({ 
                    command: "support:offers-catalog:copy", 
                    params: {
                        'from-accounting-firm-id': this.selectedAccountingFirmFromId, 
                        'to-accounting-firm-id': this.selectedAccountingFirmTargetId, 
                        '--force': true,
                        '--erase': this.eraseCatalog
                    }
                })
            .then(() => {
                this.service.executeCommand({ 
                    command: "support:document-templates:copy", 
                    params: {
                        'from-accounting-firm-id': this.selectedAccountingFirmFromId, 
                        'to-accounting-firm-id': this.selectedAccountingFirmTargetId, 
                        '--force': true,
                        '--erase': this.eraseTemplates
                    }
                })
            }).then(() => {
                this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.support.success'))
            })
        }
    }
}
</script>